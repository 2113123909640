import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import { CheckCircle } from "lucide-react";
import { colors } from "../../lib/theme";

const OrderSummary = ({ formData, totalPrice }) => {
  const userCountry = localStorage.getItem("userCountry");

  return (
    <Box sx={{ position: "sticky", top: "20px" }}>
      <Paper elevation={3} sx={{ p: 3, ml: { md: 2 } }}>
        <Typography variant="h5" gutterBottom>
          Golden Record Subscription
        </Typography>
        <Typography variant="h4" gutterBottom>
          {userCountry === "US" ? "$49" : "£49"}
        </Typography>
        <Typography variant="body1" color={colors.navy} gutterBottom>
          Includes
        </Typography>
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {[
            "Printed Gift Card",
            "1 year access of recordings through QRCode",
            "1 year of digital backups of all recordings",
            "Auto-generated Transcriptions",
            "AI assisted questions and contextual questions",
          ].map((item, index) => (
            <li
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "12px",
              }}
            >
              <CheckCircle
                size={24}
                style={{
                  marginRight: "8px",
                  color: colors.navy,
                  flexShrink: 0,
                }}
              />
              <Typography variant="body1" color={colors.navy}>
                {item}
              </Typography>
            </li>
          ))}
        </ul>
        {formData.personalizedUsb && (
          <Typography variant="body1" color={colors.navy}>
            Personalized USB Gift Set: {userCountry === "US" ? "+$22" : "+£22"}
          </Typography>
        )}
        {formData.personalizedVinyl && (
          <Typography variant="body1" color={colors.navy}>
            Personalized Vinyl Record: {userCountry === "US" ? "+$99" : "+£99"}
          </Typography>
        )}
        <Typography variant="h6" sx={{ mt: 2 }}>
          Total: {userCountry === "US" ? "$" : "£"}{totalPrice.eur.toFixed(2)}
        </Typography>
      </Paper>
    </Box>
  );
};

export default OrderSummary;
